<template>
  <div class="subTitle flexB">
    <h1>{{ title }}</h1>
    <div class="flex">
      <h2>
        {{ startDate }} ~ {{ endDate }}
        {{
          dateRangeValue == null ? "(" + $t("site-statistics-data-last") + current + $t("site-statistics-data-days")+")" : "(" + $t("site-statistics-data-last") + dateRangeValue + $t("site-statistics-data-days")+")"
        }}
      </h2>
      <select v-model="dateRangeValue" @click="handleClick" @change="handleSelect(dateRangeValue)">
        <option value="1">{{ $t("site-statistics-select-last-1-day") }}</option>
        <option value="7">{{ $t("site-statistics-select-last-7-day") }}</option>
        <option value="30" selected>{{ $t("site-statistics-select-last-30-day") }}</option>
        <option value="90">{{ $t("site-statistics-select-last-90-day") }}</option>
        <option :value="null">{{ $t("site-statistics-select-yourself") }}</option>
      </select>
    </div>
    <div :class="{ dateBox: true, active: showDatepicker }">
      <div class="flex">
        <p class="bold small">{{ $t("site-statistics-select-start-date") }}</p>
        <Datepicker
            v-model="startYmd"
            :language="ko"
            :format="customFormatter"
            placeholder="YYYY-MM-DD"
        ></Datepicker>
      </div>
      <div class="flex">
        <p class="bold small">{{ $t("site-statistics-select-end-date") }}</p>
        <Datepicker
            v-model="endYmd"
            :language="ko"
            :format="customFormatter"
            placeholder="YYYY-MM-DD"
        ></Datepicker>
      </div>
      <div class="buttonWrap">
        <button class="medium point" @click="handleSearch">{{ $t("btn-searching") }}</button>
        <button class="medium margin6" @click="handleCancel">{{ $t("btn-cancel") }}</button>
      </div>
    </div>
  </div>
</template>
<style>
select{
  width: 160px!important;
}
</style>
<script>
import moment from "moment";
import { ko } from "vuejs-datepicker/dist/locale";
import Datepicker from "vuejs-datepicker";
import { format } from "@/mixins/format";

export default {
  components: { Datepicker },
  mixins: [format],
  name: "Worksite1",
  props: {
    title: {
      type: String,
    },
  },
  data() {
    return {
      ko: ko,
      moment: moment,
      startYmd: null,
      endYmd: null,
      dateRangeValue: 0,
      showDatepicker: false,
      startDate : null,
      endDate : null,
      current : 0,
      data: {},
    };
  },
  mounted() {
    this.dateRangeValue = 30;
    this.handleSearch();
  },
  watch : {
    dateRangeValue(value){
      if(value != null) {
        let date = new Date();
        let temp = date.setDate(date.getDate() - 1);
        let tempDate = new Date(temp);
        let strt = tempDate.setDate(tempDate.getDate() - value + 1);
        this.startDate = moment(strt).format("YYYY-MM-DD");
        this.endDate = moment(temp).format("YYYY-MM-DD");
      } else {
        this.startDate = null;
        this.endDate = null;
      }
    },
    startYmd(value){
      if(this.dateRangeValue == null) {
        this.startDate = moment(value).format("YYYY-MM-DD");
      }
      if(this.endYmd != null) {
        let dateGap = new Date(this.endYmd) - new Date(value);
        this.current = dateGap / 1000 / 60 / 60 / 24;
      }
    },
    endYmd(value){
      if(this.dateRangeValue == null) {
        this.endDate = moment(value).format("YYYY-MM-DD");
      }

      if(this.startYmd != null) {
        let dateGap = new Date(value) - new Date(this.startYmd);
        this.current = dateGap / 1000 / 60 / 60 / 24;
      }
    },
  },
  methods: {
    handleSearch() {
      this.showDatepicker = false;
      if (this.dateRange(this.startYmd, this.endYmd)) {
        this.data = {
          dateRangeValue: this.dateRangeValue,
          startYmd: this.startYmd,
          endYmd: this.endYmd,
        };
        this.$emit("search", this.data);
      } else {
        alert(this.$t("alert-message-date-check"));
      }
    },
    handleClick(){
      if(this.dateRangeValue == null) {
        this.showDatepicker = true;
      }
    },
    handleSelect(val) {
      if (val == null) {
        this.showDatepicker = true;
      } else {
        this.dateRangeValue = val;
        this.handleSearch();
      }
    },
    handleCancel() {
      this.showDatepicker = false;
      this.startYmd = null;
      this.endYmd = null;
      this.current = null;
    },

    customFormatter(date) {
      return moment(date).format("YYYY년 MM월 DD일");
    },
  },
};
</script>
